import React, { useState } from "react";
import _ from "lodash"; 

import callApi from "../callApi";
import "./style.css";

const RefreshCache = () => {
    const db_cache_list = [
        'app_config', 'categories', 'discount_sources', 'merchant_offers',
        'providers', 'faqs', 'blog_entries', 'campaigns', 'credit_cards', 
        'credit_card_partners', 'card_categories', 'loan_offer_categories',
        'business_categories'
    ];

    const [refreshedCache, setRefreshedCache] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(new Array(db_cache_list.length).fill(false));

    const refreshCache = (cache, index) => {
        let url = `${process.env.REACT_APP_API_URL}/v1/core/refresh_cache`;
        callApi(url, 'POST', {'collection': cache})
            .then((json) => {
                let dbCache = _.cloneDeep(refreshedCache);
                dbCache[index] = cache;
                setRefreshedCache(dbCache);
            })
            .catch((err) => err);
    };

    return (
        <div className="index-page">
            <h1 className="title">
                Refresh Cache
            </h1>
            <div>
                <table className="table index-container">
                    <thead className="text-center align-top">
                        <tr>
                            <th className="border border-dark dobin-primary">
                                DB Cache
                            </th>
                            <th className="border border-dark dobin-primary">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="text-center align-top">
                        {
                            db_cache_list.map((cache, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="border border-dark col-md-3">
                                            {cache.toUpperCase()}
                                        </td>
                                        <td className={"border border-dark col-md-3"}>
                                            <button
                                                className={
                                                    refreshedCache[index] === cache ?
                                                    "grey-button" : "index-button"
                                                }
                                                onClick={() => {
                                                    let buttonStatus = _.cloneDeep(buttonDisabled);
                                                    buttonStatus[index] = true;
                                                    setButtonDisabled(buttonStatus);
                                                    refreshCache(cache, index);
                                                }}
                                                disabled={buttonDisabled[index]}>
                                                    Refresh
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RefreshCache
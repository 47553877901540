import _ from "lodash";


export const loan_offer_text = {
    text: ""
}


export const loan_offer_step_text = {
    steps: [_.cloneDeep({
        steps: [],
        text: ""
    })],
    text: ""
}


export const loan_offer_tenure = {
    annual_rate: "",
    monthly_rate: "",
    eir_rate: "",
    months: ""
}


export const loan_offer_document = {
    citizen_pr: {
        description: [""],
        salaried: [""],
        self_employed: [""],
        commission_based: [""],
        ofw: [""]
    },
    foreigner: {
        description: [""],
        salaried: [""],
        self_employed: [""],
        commission_based: [""],
        ofw: [""]
    },
}


export const loan_offer_min_max = {
    description: "",
    minimum: "",
    maximum: ""
}


export const loan_offer_amount_precentage = {
    description: "",
    amount: "",
    percentage: ""
}


export const loan_offer_address = {
    type: [""],
    min_stay_duration_months: 0,
    description: [""],
    province: [""],
    city: [""],
    zip_code: [""]
}


export const loan_borrowing_multiple = {
    income_multiple: "",
    income_criteria: "",
    description: ""
}


export const loan_offer_partner_promotion = {
    name: "",
    description: [""],
    criterias: [""],
    criteria_url: "",
    valid_until: "",
    fulfilments: [""]
}


export const loan_offer_promotion = {
    partner_id: "",
    application_url: "",
    promotion: _.cloneDeep(loan_offer_partner_promotion),
    dobin_promotion: _.cloneDeep(loan_offer_partner_promotion)
}


export const loan_offer_product_holding = {
    product_type: "",
    required: false,
    min_tenure_months: "",
    description: [""]
}


export const data = {
    loan_provider_id: "",
    name: "",
    currency: "SGD",
    page_url: "",
    tnc_url: "",
    app_featured: false,
    featured_position: "",
    display_order: "",
    application_process: [_.cloneDeep(loan_offer_step_text)],
    benefits: [_.cloneDeep(loan_offer_step_text)],
    identity_document: _.cloneDeep(loan_offer_document),
    income_document: _.cloneDeep(loan_offer_document),
    tooltip: "The interest rate shown is a published rate for reference only. Your actual rate will be based on your credit profile and other factors. It does not represent the Effective Interest Rate (EIR), which accounts for fees and repayment terms. Final rates are at the bank's discretion and will be confirmed during your application.",
    tenures: [_.cloneDeep(loan_offer_tenure)],
    loan_tenure: _.cloneDeep(loan_offer_min_max),
    loan_amount: _.cloneDeep(loan_offer_min_max),
    pre_termination_fee: _.cloneDeep(loan_offer_amount_precentage),
    late_payment_fee: _.cloneDeep(loan_offer_amount_precentage),
    processing_fee: _.cloneDeep(loan_offer_amount_precentage),
    documentary_stamp_tax: {
        description: "",
        amount: "",
        percentage: "",
        loan_block: "",
    },
    description: "",
    country_code: "SG",
    lender_type: "",
    type: "",
    key_features: [""],
    categories: [""],
    residence_address: _.cloneDeep(loan_offer_address),
    business_address: _.cloneDeep(loan_offer_address),
    loan_borrowing_multiple: [_.cloneDeep(loan_borrowing_multiple)],
    annual_fee: _.cloneDeep(loan_offer_amount_precentage),
    disbursement_fee: _.cloneDeep(loan_offer_amount_precentage),
    promotions: [_.cloneDeep(loan_offer_promotion)],
    eligibility: {
        citizen_status: {
            description: [""],
            citizenship_eligible: true,
            pr_eligible: true,
            foreigner_eligible: true
        },
        age: _.cloneDeep(loan_offer_min_max),
        income: {
            citizen_pr: {
                description: [""],
                salaried: "",
                self_employed: "",
                commission_based: "",
                ofw: ""
            },
            foreigner: {
                description: [""],
                salaried: "",
                self_employed: "",
                commission_based: "",
                ofw: ""
            },
        },
        phone_landline: {
            required: false,
            description: [""]
        },
        product_holding: [_.cloneDeep(loan_offer_product_holding)]
    },
    partner: "",
    qualifications: [_.cloneDeep(loan_offer_step_text)],
    submit_requirements: [_.cloneDeep(loan_offer_step_text)],
    minimum_income: {
        professional: "",
        salaried: "",
        self_employed: ""
    },
    documents_proof_of_identity: [_.cloneDeep(loan_offer_step_text)],
    document_proof_of_income: {
        cardholder: [_.cloneDeep(loan_offer_step_text)],
        ofw: [_.cloneDeep(loan_offer_step_text)],
        salaried: [_.cloneDeep(loan_offer_step_text)],
        self_employed: [_.cloneDeep(loan_offer_step_text)]
    },
    min_amount: "",
    max_amount: "",
    penalty_fee: "",
    processing_fee_amount: "",
    processing_fee_description: "",
}
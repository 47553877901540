import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import Select from 'react-select';
import Switch from "react-switch";
import _ from "lodash";

import callApi from "../callApi.js";
import { 
    data as loanOfferSchema, 
    loan_offer_tenure,
    loan_borrowing_multiple,
    loan_offer_promotion, 
    loan_offer_product_holding, 
} from "./data.js";
import StepsTextForm from "./form.js";


const LoanOfferAction = ({
    loanOffer,
    handleModalClose,
    setButtonDisabled,
    updateLoanOfferTable
}) => {
    const dataCloning = (data, schema) => {
        let result = {};
        for (let key in schema) {
            // Field missing in data, clone from schema
            if (!(key in data)) {
                result[key] = _.cloneDeep(schema[key]);
            }
            // Field present
            else {
                const schemaVal = schema[key];
                const dataVal = data[key];

                // Handle Arrays
                if (_.isArray(schemaVal)) {
                    // Empty array case in data
                    if (dataVal.length === 0) {
                        result[key] = _.cloneDeep(schemaVal);
                    } else {
                        // Array of primitive types (string, number, boolean)
                        if (typeof schemaVal[0] !== 'object' || schemaVal[0] === null) {
                            result[key] = _.cloneDeep(dataVal);
                        }
                        // Array of objects
                        else {
                            result[key] = dataVal.map(item => dataCloning(item, schemaVal[0]));
                        }
                    }
                }
                // Handle Objects (nested)
                else if (_.isPlainObject(schemaVal)) {
                    result[key] = dataCloning(dataVal, schemaVal);
                }
                // Handle Primitive types directly
                else {
                    result[key] = dataVal;
                }
            }
        }
        return result;
    };
    let loanOfferData = _.cloneDeep(loanOffer);
    let data = dataCloning(loanOfferData, loanOfferSchema);
    data.id = loanOffer.id;

    const get_ddmmyyyy_date = (date_string) => {
        if (!date_string) {
            return "";
        }
        let date = new Date(date_string);

        let utc_date = date.getUTCDate();
        if (utc_date < 10) {
            utc_date = "0" + utc_date; 
        }

        let utc_month =  date.getUTCMonth();
        utc_month = utc_month + 1;
        if (utc_month < 10) {
            utc_month = "0" + utc_month;
        }

        let utc_year = date.getFullYear();

        return utc_year + "-" + utc_month + "-" + utc_date;
    };
    if ('promotions' in data) {
        for (let index in data.promotions) {
            if ('promotion' in data.promotions[index] && 
                'valid_until' in data.promotions[index].promotion &&
                data.promotions[index].promotion.valid_until) {
                    data.promotions[index].promotion.valid_until = get_ddmmyyyy_date(
                        data.promotions[index].promotion.valid_until
                    );
                }
            if ('dobin_promotion' in data.promotions[index] && 
                'valid_until' in data.promotions[index].dobin_promotion &&
                data.promotions[index].dobin_promotion.valid_until) {
                    data.promotions[index].dobin_promotion.valid_until = get_ddmmyyyy_date(
                        data.promotions[index].dobin_promotion.valid_until
                    );
                }
        }
    }

    const [loanProviderIds, setLoanProviderIds] = useState([]);
    const [partnerIds, setPartnerIds] = useState([]);
    const [categories, setCategories] = useState([]);

    const lenderTypes = [
        { value: 'bank', label: 'Bank' },
        { value: 'digital_bank', label: 'Digital Bank' },
        { value: 'money_lender', label: 'Money Lender' }
    ];

    const types = [
        { value: 'personal_loan', label: 'Personal Loan' },
        { value: 'debt_consolidation_loan', label: 'Debt Consolidation Loan' },
        { value: 'balance_transfer', label: 'Balance Transfer' }
    ];

    const productTypes = [
        { value: 'credit_card', label: 'Credit Card' },
        { value: 'saving_account', label: 'Saving Account' },
        { value: 'cash_line', label: 'Cash Line' }
    ];

    const addressTypes = [
        { value: 'owned_self_spouse', label: 'Owned Self Spouse' },
        { value: 'owned_parents_sibilings', label: 'Owned Parents Siblings' },
        { value: 'rented', label: 'Rented' },
        { value: 'paying_guest', label: 'Paying Guest' },
        { value: 'company_provided', label: 'Company Provided' }
    ];

    const getLoanProviderIds = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/loan_providers`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.loan_providers) {
                    let allLoanProviderIds = [];
                    for (let loan_provider in json.data.loan_providers) {
                        allLoanProviderIds.push({
                            value: loan_provider,
                            label: loan_provider
                        });
                    }
                    setLoanProviderIds(allLoanProviderIds);
                }
            })
            .catch((err) => err);
    };

    const getPartnerIds = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/partners`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.partners) {
                    let allPartnerIds = [];
                    for (let partner in json.data.partners) {
                        allPartnerIds.push({
                            value: partner,
                            label: partner
                        });
                    }
                    setPartnerIds(allPartnerIds);
                }
            })
            .catch((err) => err);
    };

    const getCategories = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/loan_offers/categories`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.categories) {
                    let category_ids = Object.keys(json.data.categories);
                    let categories = [];
                    for (let category_id of category_ids) {
                        categories.push({
                            value: category_id,
                            label: category_id
                        });
                    }
                    setCategories(categories);
                }
            })
            .catch((err) => err);
    };

    useEffect(() => {
        if (categories.length === 0) {
            getCategories();
        }
    }, [categories]);

    useEffect(() => {
        if (loanProviderIds.length === 0) {
            getLoanProviderIds();
        }
    }, [loanProviderIds]);

    useEffect(() => {
        if (partnerIds.length === 0) {
            getPartnerIds();
        }
    }, [partnerIds]);

    const loan_offer_number_fields = [
        "min_amount", "max_amount", "processing_fee_amount",
        "minimum_income.professional", "minimum_income.salaried", "minimum_income.self_employed",
        "documentary_stamp_tax.amount", "documentary_stamp_tax.percentage", 'documentary_stamp_tax.loan_block',
        "featured_position", "pre_termination_fee.amount", "pre_termination_fee.percentage",
        "loan_tenure.minimum", "loan_tenure.maximum", "loan_amount.minimum", "loan_amount.maximum",
        "late_payment_fee.amount", "late_payment_fee.percentage", "processing_fee.amount", "processing_fee.percentage",
        "annual_fee.amount", "annual_fee.percentage", "disbursement_fee.amount", "disbursement_fee.percentage",
        "eligibility.income.citizen_pr.salaried", "eligibility.income.citizen_pr.self_employed",
        "eligibility.income.citzen_pr.commission_based", "eligibility.income.citizen_pr.ofw",
        "eligibility.income.foreigner.salaried", "eligibility.income.foreigner.self_employed",
        "eligibility.income.foreigner.commission_based", "eligibility.income.foreigner.ofw",
        "eligibility.age.minimum", "eligibility.age.maximum", 
        "residence_address.min_stay_duration_months", "business_address.min_stay_duration_months"
        // eligibility.product_holding min_tenure_months managed separately
        // loan_borrowing_multiple income_multiple and income_criteria managed seperately
        // tenure annual rate, monthly rate, eir rate and months fields managed separately
    ];

    const loan_offer_number_field_names = [
        "min_amount", "max_amount", "minimum", "maximum", "income_multiple", "income_criteria",
        "professional", "salaried", "self_employed", "commission_based", "ofw", 
        "amount", "percentage", "loan_block", "featured_position", "processing_fee_amount",
        "annual_rate", "monthly_rate", "eir_rate", "months", "min_stay_duration_months", "min_tenure_months"
    ];

    const dataTransformations = (data) => {
        let result = {};
        for (let key in data) {
            if ((typeof(data[key]) == "string" && 
                loan_offer_number_field_names.includes(key) &&
                data[key] !== "") || (typeof(data[key]) == "number" && 
                loan_offer_number_field_names.includes(key))) {
                    result[key] = parseFloat(data[key]);
            }
            else if(typeof(data[key]) == "string" && data[key] !== "") {
                result[key] = data[key];
            }
            else if(_.isArray(data[key]) && data[key].length > 0) {
                let items = [];
                for (let value of data[key]) {
                    if (typeof(value) == "string" && value !== "") {
                        items.push(value);
                    }
                    else if (value && typeof(value) == "object") {
                        let object_result = dataTransformations(value);
                        if (!_.isEmpty(object_result)) {
                            items.push(object_result);
                        }
                    }
                }
                if(items.length > 0) {
                    result[key] = items;
                }
            }
            else if(data[key] && typeof(data[key]) == "object") {
                let object_result = dataTransformations(data[key]);
                if (!_.isEmpty(object_result)) {
                    result[key] = object_result;
                }
            }
            else if(typeof(data[key]) == "boolean") {
                result[key] = data[key];
            }
        }
        return result;
    };

    const updateLoanOffer = (loan_offer, setSubmitting) => {
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/loan_offers`;
        callApi(url, 'PUT', {'loan_offers': [loan_offer]})
            .then((json) => {
                setSubmitting(false);
                setButtonDisabled(false);
                handleModalClose();
                updateLoanOfferTable();
            })
            .catch((err) => err);
    };

    return (
        <div className="index-page">
            <Formik
                initialValues={data}
                validate={values => {
                    const isNumberRegex = /^-?\d*\.?\d*$/;
                    const errors = {};
                    if (!values.loan_provider_id) {
                        errors.loan_provider_id = 'Required';
                    }
                    for (let value of [
                        'page_url', 'tnc_url'
                    ]) {
                        if (_.get(values, value) && !_.get(values, value).startsWith('https://')) {
                            _.set(errors, value, 'Invalid URL');
                        }
                    }
                    values['promotions'].map((promotion, index) => {
                        for (let value of [
                            'application_url', 'promotion.criteria_url', 'dobin_promotion.criteria_url'
                        ]) {
                            if (_.get(promotion, value) && !_.get(promotion, value).startsWith('https://')) {
                                _.set(errors, `promotions.${index}.${value}`, 'Invalid URL');
                            }
                        }
                    })
                    for (let value of loan_offer_number_fields) {
                        if (_.get(values, value) && !(isNumberRegex.test(_.get(values, value)))) {
                            _.set(errors, value, "Invalid Number")
                        }
                    }
                    if (values.tenures) {
                        values.tenures.map((tenure, index) => {
                            if (tenure.annual_rate && !(isNumberRegex.test(tenure.annual_rate))) {
                                _.set(errors, `tenures.${index}.annual_rate`, "Invalid Number")
                            }
                            if (tenure.monthly_rate && !(isNumberRegex.test(tenure.monthly_rate))) {
                                _.set(errors, `tenures.${index}.monthly_rate`, "Invalid Number")
                            }
                            if (tenure.eir_rate && !(isNumberRegex.test(tenure.eir_rate))) {
                                _.set(errors, `tenures.${index}.eir_rate`, "Invalid Number")
                            }
                            if (tenure.months && !(isNumberRegex.test(tenure.months))) {
                                _.set(errors, `tenures.${index}.months`, "Invalid Number")
                            }
                        })
                    }
                    values['loan_borrowing_multiple'].map((item, index) => {
                        for (let value of [
                            'income_multiple', 'income_criteria'
                        ]) {
                            if (_.get(item, value) && !(isNumberRegex.test(_.get(item, value)))) {
                                _.set(errors, `loan_borrowing_multiple.${index}.${value}`, "Invalid Number")
                            }
                        }
                    })
                    if (values.eligibility && values.eligibility.product_holding) {
                        values['eligibility']['product_holding'].map((item, index) => {
                            if (item.min_tenure_months && !(isNumberRegex.test(item.min_tenure_months))) {
                                _.set(errors, `eligibility.product_holding.${index}.min_tenure_months`, "Invalid Number")
                            }
                        })
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    setButtonDisabled(true);
                    let loan_offer = dataTransformations(values);
                    updateLoanOffer(loan_offer, setSubmitting);
                }}
                >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form id="loan-offer-edit">
                        <h4>
                            Basic Details
                        </h4>
                        <div className="row">
                            <label className="col-md-2">ID:*</label>
                            <Field 
                                className="col-md-8" 
                                readOnly type="text" name="id" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Loan Provider Id:*</label>
                            <Select
                                className="col-md-8"
                                name="loan_provider_id"
                                options={loanProviderIds}
                                defaultValue={{
                                    value: values.loan_provider_id,
                                    label: values.loan_provider_id
                                }}
                                onChange={(selected) => setFieldValue("loan_provider_id", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="loan_provider_id" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Name:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="name" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Currency:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="currency" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Page URL:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="page_url" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="page_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">TnC URL:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="tnc_url" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="tnc_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Featured Position:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="featured_position" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="featured_position" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Description:</label>
                            <Field 
                                className="col-md-8"
                                as="textarea"
                                type="text" name="description" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="description" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Country Code:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="country_code" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="country_code" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Lender Type:</label>
                            <Select
                                className="col-md-8"
                                name="lender_type"
                                options={lenderTypes}
                                defaultValue={
                                    { 
                                        value: values.lender_type, 
                                        label: (function() {
                                            for (let lender_type of lenderTypes) {
                                                if (lender_type.value === values.lender_type) {
                                                    return lender_type.label
                                                } 
                                            }
                                            return values.lender_type
                                        }())
                                    }
                                }
                                onChange={(selected) => setFieldValue("lender_type", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="lender_type" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Type:</label>
                            <Select
                                className="col-md-8"
                                name="type"
                                options={types}
                                defaultValue={
                                    { 
                                        value: values.type, 
                                        label: (function() {
                                            for (let type of types) {
                                                if (type.value === values.type) {
                                                    return type.label
                                                } 
                                            }
                                            return values.type
                                        }())
                                    }
                                }
                                onChange={(selected) => setFieldValue("type", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="type" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Key Features:</label>
                            <FieldArray
                                className="col-md-8"
                                name="key_features"
                                render={arrayHelpers => (
                                <div>
                                    {values.key_features && values.key_features.length > 0 ? (
                                    values.key_features.map((key_feature, index) => (
                                        <div key={index}>
                                            <Field className="col-md-6" name={`key_features.${index}`} />
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index, '')}
                                            >
                                                +
                                            </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push('')}>
                                        Add Key Feature
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Categories:</label>
                            <Select
                                isMulti
                                className="col-md-8"
                                name="categories"
                                options={categories}
                                defaultValue={
                                    values.categories.map(category => {
                                        return {
                                            label: category,
                                            value: category
                                        };
                                    })
                                }
                                onChange={(selected) => {
                                    let category_list = [];
                                    for (let item of selected) {
                                        category_list.push(item.value);
                                    }
                                    setFieldValue("categories", category_list);
                                }} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Application Process
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Application Process:</label>
                            <StepsTextForm namePrefix={`application_process`} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Benefits
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Benefits:</label>
                            <StepsTextForm namePrefix={`benefits`} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Identity Document
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Identity Document:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Citizens/PR:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Description:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="identity_document.citizen_pr.description"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.identity_document && values.identity_document.citizen_pr &&
                                            values.identity_document.citizen_pr.description && values.identity_document.citizen_pr.description.length > 0 ? (
                                            values.identity_document.citizen_pr.description.map((descripion, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`identity_document.citizen_pr.description.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Description
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Salaried:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="identity_document.citizen_pr.salaried"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.identity_document && values.identity_document.citizen_pr &&
                                            values.identity_document.citizen_pr.salaried && values.identity_document.citizen_pr.salaried.length > 0 ? (
                                            values.identity_document.citizen_pr.salaried.map((salaried, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`identity_document.citizen_pr.salaried.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Salaried
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Self Employed:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="identity_document.citizen_pr.self_employed"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.identity_document && values.identity_document.citizen_pr &&
                                            values.identity_document.citizen_pr.self_employed && values.identity_document.citizen_pr.self_employed.length > 0 ? (
                                            values.identity_document.citizen_pr.self_employed.map((self_employed, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`identity_document.citizen_pr.self_employed.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Self Employed
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Commission Based:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="identity_document.citizen_pr.commission_based"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.identity_document && values.identity_document.citizen_pr &&
                                            values.identity_document.citizen_pr.commission_based && values.identity_document.citizen_pr.commission_based.length > 0 ? (
                                            values.identity_document.citizen_pr.commission_based.map((commission_based, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`identity_document.citizen_pr.commission_based.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Commission Based
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">OFW:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="identity_document.citizen_pr.ofw"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.identity_document && values.identity_document.citizen_pr &&
                                            values.identity_document.citizen_pr.ofw && values.identity_document.citizen_pr.ofw.length > 0 ? (
                                            values.identity_document.citizen_pr.ofw.map((ofw, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`identity_document.citizen_pr.ofw.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add OFW
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Foreigner:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Description:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="identity_document.foreigner.description"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.identity_document && values.identity_document.foreigner &&
                                            values.identity_document.foreigner.description && values.identity_document.foreigner.description.length > 0 ? (
                                            values.identity_document.foreigner.description.map((descripion, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`identity_document.foreigner.description.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Description
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Salaried:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="identity_document.foreigner.salaried"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.identity_document && values.identity_document.foreigner &&
                                            values.identity_document.foreigner.salaried && values.identity_document.foreigner.salaried.length > 0 ? (
                                            values.identity_document.foreigner.salaried.map((salaried, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`identity_document.foreigner.salaried.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Salaried
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Self Employed:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="identity_document.foreigner.self_employed"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.identity_document && values.identity_document.foreigner &&
                                            values.identity_document.foreigner.self_employed && values.identity_document.foreigner.self_employed.length > 0 ? (
                                            values.identity_document.foreigner.self_employed.map((self_employed, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`identity_document.foreigner.self_employed.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Self Employed
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Commission Based:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="identity_document.foreigner.commission_based"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.identity_document && values.identity_document.foreigner &&
                                            values.identity_document.foreigner.commission_based && values.identity_document.foreigner.commission_based.length > 0 ? (
                                            values.identity_document.foreigner.commission_based.map((commission_based, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`identity_document.foreigner.commission_based.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Commission Based
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">OFW:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="identity_document.foreigner.ofw"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.identity_document && values.identity_document.foreigner &&
                                            values.identity_document.foreigner.ofw && values.identity_document.foreigner.ofw.length > 0 ? (
                                            values.identity_document.foreigner.ofw.map((ofw, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`identity_document.foreigner.ofw.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add OFW
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Income Document
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Income Document:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Citizens/PR:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Description:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="income_document.citizen_pr.description"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.income_document && values.income_document.citizen_pr &&
                                            values.income_document.citizen_pr.description && values.income_document.citizen_pr.description.length > 0 ? (
                                            values.income_document.citizen_pr.description.map((descripion, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`income_document.citizen_pr.description.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Description
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Salaried:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="income_document.citizen_pr.salaried"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.income_document && values.income_document.citizen_pr &&
                                            values.income_document.citizen_pr.salaried && values.income_document.citizen_pr.salaried.length > 0 ? (
                                            values.income_document.citizen_pr.salaried.map((salaried, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`income_document.citizen_pr.salaried.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Salaried
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Self Employed:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="income_document.citizen_pr.self_employed"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.income_document && values.income_document.citizen_pr &&
                                            values.income_document.citizen_pr.self_employed && values.income_document.citizen_pr.self_employed.length > 0 ? (
                                            values.income_document.citizen_pr.self_employed.map((self_employed, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`income_document.citizen_pr.self_employed.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Self Employed
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Commission Based:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="income_document.citizen_pr.commission_based"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.income_document && values.income_document.citizen_pr &&
                                            values.income_document.citizen_pr.commission_based && values.income_document.citizen_pr.commission_based.length > 0 ? (
                                            values.income_document.citizen_pr.commission_based.map((commission_based, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`income_document.citizen_pr.commission_based.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Commission Based
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">OFW:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="income_document.citizen_pr.ofw"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.income_document && values.income_document.citizen_pr &&
                                            values.income_document.citizen_pr.ofw && values.income_document.citizen_pr.ofw.length > 0 ? (
                                            values.income_document.citizen_pr.ofw.map((ofw, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`income_document.citizen_pr.ofw.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add OFW
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Foreigner:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Description:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="income_document.foreigner.description"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.income_document && values.income_document.foreigner &&
                                            values.income_document.foreigner.description && values.income_document.foreigner.description.length > 0 ? (
                                            values.income_document.foreigner.description.map((descripion, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`income_document.foreigner.description.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Description
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Salaried:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="income_document.foreigner.salaried"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.income_document && values.income_document.foreigner &&
                                            values.income_document.foreigner.salaried && values.income_document.foreigner.salaried.length > 0 ? (
                                            values.income_document.foreigner.salaried.map((salaried, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`income_document.foreigner.salaried.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Salaried
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Self Employed:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="income_document.foreigner.self_employed"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.income_document && values.income_document.foreigner &&
                                            values.income_document.foreigner.self_employed && values.income_document.foreigner.self_employed.length > 0 ? (
                                            values.income_document.foreigner.self_employed.map((self_employed, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`income_document.foreigner.self_employed.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Self Employed
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Commission Based:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="income_document.foreigner.commission_based"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.income_document && values.income_document.foreigner &&
                                            values.income_document.foreigner.commission_based && values.income_document.foreigner.commission_based.length > 0 ? (
                                            values.income_document.foreigner.commission_based.map((commission_based, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`income_document.foreigner.commission_based.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Commission Based
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">OFW:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="income_document.foreigner.ofw"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.income_document && values.income_document.foreigner &&
                                            values.income_document.foreigner.ofw && values.income_document.foreigner.ofw.length > 0 ? (
                                            values.income_document.foreigner.ofw.map((ofw, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`income_document.foreigner.ofw.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add OFW
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Loan Tenures
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Tooltip:</label>
                            <Field 
                                className="col-md-8"
                                as="textarea"
                                type="text" name="tooltip" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Loan Tenure:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="loan_tenure.description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Minimum:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="loan_tenure.minimum" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="loan_tenure.minimum" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Maximum:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="loan_tenure.maximum" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="loan_tenure.maximum" component="div" />
                            </div>
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Tenures:</label>
                            <FieldArray
                                className="col-md-8"
                                name="tenures"
                                render={arrayHelpers => (
                                <div>
                                    {values.tenures && values.tenures.length > 0 ? (
                                    values.tenures.map((item, index) => (
                                        <div key={index}>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Annual Rate:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    type="text" name={`tenures.${index}.annual_rate`}
                                                    onChange={(e) => {
                                                        const annualRate = parseFloat(e.target.value) || 0;
                                                        const calculateMonthlyRate = (annualRate) => {
                                                            return (Math.round(annualRate / 12 * 100) / 100).toString();
                                                        };
                                                        setFieldValue(`tenures.${index}.annual_rate`, e.target.value);
                                                        setFieldValue(`tenures.${index}.monthly_rate`, calculateMonthlyRate(annualRate));
                                                    }} />
                                                <ErrorMessage 
                                                    className="text-danger" 
                                                    name={`tenures.${index}.annual_rate`} component="div" />
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Monthly Rate:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    type="text" name={`tenures.${index}.monthly_rate`} />
                                                <ErrorMessage 
                                                    className="text-danger" 
                                                    name={`tenures.${index}.monthly_rate`} component="div" />
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">EIR Rate:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    type="text" name={`tenures.${index}.eir_rate`} />
                                                <ErrorMessage 
                                                    className="text-danger" 
                                                    name={`tenures.${index}.eir_rate`} component="div" />
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Months:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    type="text" name={`tenures.${index}.months`} />
                                                <ErrorMessage 
                                                    className="text-danger" 
                                                    name={`tenures.${index}.months`} component="div" />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.push(_.cloneDeep(loan_offer_tenure))}
                                            >
                                                +
                                            </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push(_.cloneDeep(loan_offer_tenure))}>
                                        Add Tenure
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Loan Amount
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Loan Amount:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="loan_amount.description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Minimum:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="loan_amount.minimum" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="loan_amount.minimum" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Maximum:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="loan_amount.maximum" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="loan_amount.maximum" component="div" />
                            </div>
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Pre Termination Fee
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Pre Termination Fee:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="pre_termination_fee.description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Amount:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="pre_termination_fee.amount" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="pre_termination_fee.amount" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Percentage:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="pre_termination_fee.percentage" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="pre_termination_fee.percentage" component="div" />
                            </div>
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                           Late Payment
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Late Payment:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="late_payment_fee.description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Amount:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="late_payment_fee.amount" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="late_payment_fee.amount" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Percentage:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="late_payment_fee.percentage" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="late_payment_fee.percentage" component="div" />
                            </div>
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                           Processing Fee
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Processing Fee:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="processing_fee.description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Amount:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="processing_fee.amount" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="processing_fee.amount" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Percentage:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="processing_fee.percentage" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="processing_fee.percentage" component="div" />
                            </div>
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Documentary Stamp Tax
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Documentary Stamp Tax:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="documentary_stamp_tax.description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Amount:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="documentary_stamp_tax.amount" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="documentary_stamp_tax.amount" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Percentage:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="documentary_stamp_tax.percentage" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="documentary_stamp_tax.percentage" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Loan Block:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="documentary_stamp_tax.loan_block" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="documentary_stamp_tax.loan_block" component="div" />
                            </div>
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Addresses
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Residence Address:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Min Stay Duration Months:</label>
                                <Field 
                                    className="col-md-8" 
                                    type="text" name="residence_address.min_stay_duration_months" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="residence_address.min_stay_duration_months" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Type:</label>
                                <Select
                                    isMulti
                                    className="col-md-8"
                                    name="residence_address.type"
                                    options={addressTypes}
                                    defaultValue={
                                        values.residence_address.type.map(type => {
                                            return { 
                                                value: type, 
                                                label: (function() {
                                                    for (let address_type of addressTypes) {
                                                        if (address_type.value === type) {
                                                            return address_type.label
                                                        }
                                                    }
                                                    return type
                                                }())
                                            }
                                        })
                                    }
                                    onChange={(selected) => {
                                        let types = [];
                                        for (let item of selected) {
                                            types.push(item.value);
                                        }
                                        setFieldValue("residence_address.type", types);
                                    }} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="residence_address.description"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.residence_address && values.residence_address.description &&
                                        values.residence_address.description.length > 0 ? (
                                        values.residence_address.description.map((descripion, index) => (
                                            <div key={index}>
                                                <Field className="col-md-6" name={`residence_address.description.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Description
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Province:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="residence_address.province"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.residence_address && values.residence_address.province &&
                                        values.residence_address.province.length > 0 ? (
                                        values.residence_address.province.map((province, index) => (
                                            <div key={index}>
                                                <Field className="col-md-6" name={`residence_address.province.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Province
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">City:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="residence_address.city"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.residence_address && values.residence_address.city &&
                                        values.residence_address.city.length > 0 ? (
                                        values.residence_address.city.map((city, index) => (
                                            <div key={index}>
                                                <Field className="col-md-6" name={`residence_address.city.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add City
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Zip Code:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="residence_address.zip_code"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.residence_address && values.residence_address.zip_code &&
                                        values.residence_address.zip_code.length > 0 ? (
                                        values.residence_address.zip_code.map((zip_code, index) => (
                                            <div key={index}>
                                                <Field className="col-md-6" name={`residence_address.zip_code.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Zip Code
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Business Address:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Min Stay Duration Months:</label>
                                <Field 
                                    className="col-md-8" 
                                    type="text" name="business_address.min_stay_duration_months" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="business_address.min_stay_duration_months" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Type:</label>
                                <Select
                                    isMulti
                                    className="col-md-8"
                                    name="business_address.type"
                                    options={addressTypes}
                                    defaultValue={
                                        values.business_address.type.map(type => {
                                            return { 
                                                value: type, 
                                                label: (function() {
                                                    for (let address_type of addressTypes) {
                                                        if (address_type.value === type) {
                                                            return address_type.label
                                                        }
                                                    }
                                                    return type
                                                }())
                                            }
                                        })
                                    }
                                    onChange={(selected) => {
                                        let types = [];
                                        for (let item of selected) {
                                            types.push(item.value);
                                        }
                                        setFieldValue("business_address.type", types);
                                    }} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="business_address.description"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.business_address && values.business_address.description &&
                                        values.business_address.description.length > 0 ? (
                                        values.business_address.description.map((descripion, index) => (
                                            <div key={index}>
                                                <Field className="col-md-6" name={`business_address.description.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Description
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Province:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="business_address.province"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.business_address && values.business_address.province &&
                                        values.business_address.province.length > 0 ? (
                                        values.business_address.province.map((province, index) => (
                                            <div key={index}>
                                                <Field className="col-md-6" name={`business_address.province.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Province
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">City:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="business_address.city"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.business_address && values.business_address.city &&
                                        values.business_address.city.length > 0 ? (
                                        values.business_address.city.map((city, index) => (
                                            <div key={index}>
                                                <Field className="col-md-6" name={`business_address.city.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add City
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Zip Code:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="business_address.zip_code"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.business_address && values.business_address.zip_code &&
                                        values.business_address.zip_code.length > 0 ? (
                                        values.business_address.zip_code.map((zip_code, index) => (
                                            <div key={index}>
                                                <Field className="col-md-6" name={`business_address.zip_code.${index}`} />
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.insert(index, '')}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Zip Code
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Loan Borrowing Multiple
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Loan Borrowing Multiple:</label>
                            <FieldArray
                                className="col-md-8"
                                name="loan_borrowing_multiple"
                                render={arrayHelpers => (
                                <div>
                                    {values.loan_borrowing_multiple && values.loan_borrowing_multiple.length > 0 ? (
                                    values.loan_borrowing_multiple.map((item, index) => (
                                        <div key={index}>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Description:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    type="text" name={`loan_borrowing_multiple.${index}.description`} />
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Income Multiple:</label>
                                                <Field
                                                    className="col-md-8"
                                                    type="text" name={`loan_borrowing_multiple.${index}.income_multiple`} />
                                                <ErrorMessage 
                                                    className="text-danger" 
                                                    name={`loan_borrowing_multiple.${index}.income_multiple`} component="div" />
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Income Criteria:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    type="text" name={`loan_borrowing_multiple.${index}.income_criteria`} />
                                                <ErrorMessage 
                                                    className="text-danger" 
                                                    name={`loan_borrowing_multiple.${index}.income_criteria`} component="div" />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.push(_.cloneDeep(loan_borrowing_multiple))}
                                            >
                                                +
                                            </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push(_.cloneDeep(loan_borrowing_multiple))}>
                                        Add Loan Borrowing Multiple
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Annual Fee
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Annual Fee:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="annual_fee.description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Amount:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="annual_fee.amount" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="annual_fee.amount" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Percentage:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="annual_fee.percentage" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="annual_fee.percentage" component="div" />
                            </div>
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Disbursement Fee
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Disbursement Fee:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="disbursement_fee.description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Amount:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="disbursement_fee.amount" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="disbursement_fee.amount" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Percentage:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="disbursement_fee.percentage" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="disbursement_fee.percentage" component="div" />
                            </div>
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Promotions
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Promotions:</label>
                            <FieldArray
                                className="col-md-8"
                                name="promotions"
                                render={arrayHelpers => (
                                <div>
                                    {values.promotions && values.promotions.length > 0 ? (
                                    values.promotions.map((promotion, index) => (
                                        <div key={index}>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Partner Id:</label>
                                                <Select
                                                    className="col-md-8"
                                                    name={`promotions.${index}.partner_id`}
                                                    options={partnerIds}
                                                    defaultValue={{
                                                        value: values.promotions[index].partner_id,
                                                        label: values.promotions[index].partner_id
                                                    }}
                                                    onChange={(selected) => setFieldValue(`promotions.${index}.partner_id`, selected.value)} />
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Application URL:</label>
                                                <Field 
                                                    className="col-md-8" 
                                                    type="text" name={`promotions.${index}.application_url`} />
                                                <ErrorMessage 
                                                    className="text-danger" 
                                                    name={`promotions.${index}.application_url`} component="div" />
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Promotion:</label>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Name:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" 
                                                        name={`promotions.${index}.promotion.name`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Description:</label>
                                                    <FieldArray
                                                        className="col-md-8"
                                                        name={`promotions.${index}.promotion.description`} 
                                                        render={arrayHelpers => (
                                                        <div>
                                                            {values.promotions[index].promotion.description &&
                                                            values.promotions[index].promotion.description.length > 0 ? (
                                                            values.promotions[index].promotion.description.map((description, sub_index) => (
                                                                <div key={index + 'description' + sub_index}>
                                                                    <Field className="col-md-6" name={`promotions.${index}.promotion.description.${sub_index}`} />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(sub_index)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.insert(sub_index, '')}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            ))
                                                            ) : (
                                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                                Add Description
                                                            </button>
                                                            )}
                                                        </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Criterias:</label>
                                                    <FieldArray
                                                        className="col-md-8"
                                                        name={`promotions.${index}.promotion.criterias`} 
                                                        render={arrayHelpers => (
                                                        <div>
                                                            {values.promotions[index].promotion.criterias &&
                                                            values.promotions[index].promotion.criterias.length > 0 ? (
                                                            values.promotions[index].promotion.criterias.map((criteria, sub_index) => (
                                                                <div key={index + 'criteria' + sub_index}>
                                                                    <Field className="col-md-6" name={`promotions.${index}.promotion.criterias.${sub_index}`} />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(sub_index)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.insert(sub_index, '')}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            ))
                                                            ) : (
                                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                                Add Criteria
                                                            </button>
                                                            )}
                                                        </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Criteria URL:</label>
                                                    <Field
                                                        className="col-md-8"
                                                        type="text" 
                                                        name={`promotions.${index}.promotion.criteria_url`} />
                                                    <ErrorMessage 
                                                        className="text-danger" 
                                                        name={`promotions.${index}.promotion.criteria_url`} component="div" />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Fulfilments:</label>
                                                    <FieldArray
                                                        className="col-md-8"
                                                        name={`promotions.${index}.promotion.fulfilments`} 
                                                        render={arrayHelpers => (
                                                        <div>
                                                            {values.promotions[index].promotion.fulfilments &&
                                                            values.promotions[index].promotion.fulfilments.length > 0 ? (
                                                            values.promotions[index].promotion.fulfilments.map((fulfilment, sub_index) => (
                                                                <div key={index + 'fulfilment' + sub_index}>
                                                                    <Field className="col-md-6" name={`promotions.${index}.promotion.fulfilments.${sub_index}`} />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(sub_index)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.insert(sub_index, '')}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            ))
                                                            ) : (
                                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                                Add Fulfilment
                                                            </button>
                                                            )}
                                                        </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Valid Until:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="date" 
                                                        name={`promotions.${index}.promotion.valid_until`}/>
                                                </div>
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Dobin Promotion:</label>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Name:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="text" 
                                                        name={`promotions.${index}.dobin_promotion.name`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Description:</label>
                                                    <FieldArray
                                                        className="col-md-8"
                                                        name={`promotions.${index}.dobin_promotion.description`} 
                                                        render={arrayHelpers => (
                                                        <div>
                                                            {values.promotions[index].dobin_promotion.description &&
                                                            values.promotions[index].dobin_promotion.description.length > 0 ? (
                                                            values.promotions[index].dobin_promotion.description.map((description, sub_index) => (
                                                                <div key={index + 'description' + sub_index}>
                                                                    <Field className="col-md-6" name={`promotions.${index}.dobin_promotion.description.${sub_index}`} />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(sub_index)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.insert(sub_index, '')}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            ))
                                                            ) : (
                                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                                Add Description
                                                            </button>
                                                            )}
                                                        </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Criterias:</label>
                                                    <FieldArray
                                                        className="col-md-8"
                                                        name={`promotions.${index}.dobin_promotion.criterias`} 
                                                        render={arrayHelpers => (
                                                        <div>
                                                            {values.promotions[index].dobin_promotion.criterias &&
                                                            values.promotions[index].dobin_promotion.criterias.length > 0 ? (
                                                            values.promotions[index].dobin_promotion.criterias.map((criteria, sub_index) => (
                                                                <div key={index + 'criteria' + sub_index}>
                                                                    <Field className="col-md-6" name={`promotions.${index}.dobin_promotion.criterias.${sub_index}`} />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(sub_index)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.insert(sub_index, '')}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            ))
                                                            ) : (
                                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                                Add Criteria
                                                            </button>
                                                            )}
                                                        </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Criteria URL:</label>
                                                    <Field
                                                        className="col-md-8"
                                                        type="text" 
                                                        name={`promotions.${index}.dobin_promotion.criteria_url`} />
                                                    <ErrorMessage 
                                                        className="text-danger" 
                                                        name={`promotions.${index}.dobin_promotion.criteria_url`} component="div" />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Fulfilments:</label>
                                                    <FieldArray
                                                        className="col-md-8"
                                                        name={`promotions.${index}.dobin_promotion.fulfilments`} 
                                                        render={arrayHelpers => (
                                                        <div>
                                                            {values.promotions[index].dobin_promotion.fulfilments &&
                                                            values.promotions[index].dobin_promotion.fulfilments.length > 0 ? (
                                                            values.promotions[index].dobin_promotion.fulfilments.map((fulfilment, sub_index) => (
                                                                <div key={index + 'fulfilment' + sub_index}>
                                                                    <Field className="col-md-6" name={`promotions.${index}.dobin_promotion.fulfilments.${sub_index}`} />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(sub_index)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.insert(sub_index, '')}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            ))
                                                            ) : (
                                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                                Add Fulfilment
                                                            </button>
                                                            )}
                                                        </div>
                                                        )}
                                                    />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Valid Until:</label>
                                                    <Field 
                                                        className="col-md-8"
                                                        type="date" 
                                                        name={`promotions.${index}.dobin_promotion.valid_until`}/>
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.push(_.cloneDeep(loan_offer_promotion))}
                                            >
                                                +
                                            </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push(_.cloneDeep(loan_offer_promotion))}>
                                        Add Promotion
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Eligibility
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Eligibility:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Citizen Status:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Description:</label>
                                    <FieldArray
                                        className="col-md-8"
                                        name="eligibility.citizen_status.description"
                                        render={arrayHelpers => (
                                        <div>
                                            {values.eligibility && values.eligibility.citizen_status &&
                                            values.eligibility.citizen_status.description && values.eligibility.citizen_status.description.length > 0 ? (
                                            values.eligibility.citizen_status.description.map((description, index) => (
                                                <div key={index}>
                                                    <Field className="col-md-6" name={`eligibility.citizen_status.description.${index}`} />
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            ))
                                            ) : (
                                            <button type="button" onClick={() => arrayHelpers.push('')}>
                                                Add Description
                                            </button>
                                            )}
                                        </div>
                                        )}
                                    />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Citizenship Eligible:</label>
                                    <Switch
                                        className="col-md-8"
                                        checked={values.eligibility.citizen_status.citizenship_eligible}
                                        onChange={() => setFieldValue("eligibility.citizen_status.citizenship_eligible", !values.eligibility.citizen_status.citizenship_eligible)} />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">PR Eligible:</label>
                                    <Switch
                                        className="col-md-8"
                                        checked={values.eligibility.citizen_status.pr_eligible}
                                        onChange={() => setFieldValue("eligibility.citizen_status.pr_eligible", !values.eligibility.citizen_status.pr_eligible)} />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Foreigner Eligible:</label>
                                    <Switch
                                        className="col-md-8"
                                        checked={values.eligibility.citizen_status.foreigner_eligible}
                                        onChange={() => setFieldValue("eligibility.citizen_status.foreigner_eligible", !values.eligibility.citizen_status.foreigner_eligible)} />
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Age:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Description:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="eligibility.age.description" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Minimum:</label>
                                    <Field
                                        className="col-md-8"
                                        type="text" name="eligibility.age.minimum" />
                                    <ErrorMessage 
                                        className="text-danger" 
                                        name="eligibility.age.minimum" component="div" />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Maximum:</label>
                                    <Field 
                                        className="col-md-8"
                                        type="text" name="eligibility.age.maximum" />
                                    <ErrorMessage 
                                        className="text-danger" 
                                        name="eligibility.age.maximum" component="div" />
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Income:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Citizens/PR:</label>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Description:</label>
                                        <FieldArray
                                            className="col-md-8"
                                            name="eligibility.income.citizen_pr.description"
                                            render={arrayHelpers => (
                                            <div>
                                                {values.eligibility && values.eligibility.income &&
                                                values.eligibility.income.citizen_pr && values.eligibility.income.citizen_pr.description.length > 0 ? (
                                                values.eligibility.income.citizen_pr.description.map((description, index) => (
                                                    <div key={index}>
                                                        <Field className="col-md-6" name={`eligibility.income.citizen_pr.description.${index}`} />
                                                        <button
                                                            type="button"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            -
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => arrayHelpers.insert(index, '')}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                ))
                                                ) : (
                                                <button type="button" onClick={() => arrayHelpers.push('')}>
                                                    Add Description
                                                </button>
                                                )}
                                            </div>
                                            )}
                                        />
                                    </div>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Salaried:</label>
                                        <Field
                                            className="col-md-8"
                                            type="text" name="eligibility.income.citizen_pr.salaried" />
                                    </div>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Self Employed:</label>
                                        <Field
                                            className="col-md-8"
                                            type="text" name="eligibility.income.citizen_pr.self_employed" />
                                    </div>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Commission Based:</label>
                                        <Field
                                            className="col-md-8"
                                            type="text" name="eligibility.income.citizen_pr.commission_based" />
                                    </div>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">OFW:</label>
                                        <Field
                                            className="col-md-8"
                                            type="text" name="eligibility.income.citizen_pr.ofw" />
                                    </div>
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Foreigner:</label>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Description:</label>
                                        <FieldArray
                                            className="col-md-8"
                                            name="eligibility.income.foreigner.description"
                                            render={arrayHelpers => (
                                            <div>
                                                {values.eligibility && values.eligibility.income &&
                                                values.eligibility.income.foreigner && values.eligibility.income.foreigner.description.length > 0 ? (
                                                values.eligibility.income.foreigner.description.map((description, index) => (
                                                    <div key={index}>
                                                        <Field className="col-md-6" name={`eligibility.income.foreigner.description.${index}`} />
                                                        <button
                                                            type="button"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            -
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => arrayHelpers.insert(index, '')}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                ))
                                                ) : (
                                                <button type="button" onClick={() => arrayHelpers.push('')}>
                                                    Add Description
                                                </button>
                                                )}
                                            </div>
                                            )}
                                        />
                                    </div>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Salaried:</label>
                                        <Field
                                            className="col-md-8"
                                            type="text" name="eligibility.income.foreigner.salaried" />
                                    </div>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Self Employed:</label>
                                        <Field
                                            className="col-md-8"
                                            type="text" name="eligibility.income.foreigner.self_employed" />
                                    </div>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">Commission Based:</label>
                                        <Field
                                            className="col-md-8"
                                            type="text" name="eligibility.income.foreigner.commission_based" />
                                    </div>
                                    <div className="row mt-40 px-5">
                                        <label className="col-md-2">OFW:</label>
                                        <Field
                                            className="col-md-8"
                                            type="text" name="eligibility.income.foreigner.ofw" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Phone Landline:</label>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Required:</label>
                                    <Switch
                                        className="col-md-8"
                                        checked={values.eligibility.phone_landline.required}
                                        onChange={() => setFieldValue("eligibility.phone_landline.required", !values.eligibility.phone_landline.required)} />
                                </div>
                                <div className="row mt-40 px-5">
                                    <label className="col-md-2">Description:</label>
                                    <FieldArray
                                            className="col-md-8"
                                            name="eligibility.phone_landline.description"
                                            render={arrayHelpers => (
                                            <div>
                                                {values.eligibility && values.eligibility.phone_landline &&
                                                values.eligibility.phone_landline.description && values.eligibility.phone_landline.description.length > 0 ? (
                                                values.eligibility.phone_landline.description.map((description, index) => (
                                                    <div key={index}>
                                                        <Field className="col-md-6" name={`eligibility.phone_landline.description.${index}`} />
                                                        <button
                                                            type="button"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            -
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => arrayHelpers.insert(index, '')}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                ))
                                                ) : (
                                                <button type="button" onClick={() => arrayHelpers.push('')}>
                                                    Add Description
                                                </button>
                                                )}
                                            </div>
                                            )}
                                        />
                                </div>
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Product Holding:</label>
                                <FieldArray
                                    className="col-md-8"
                                    name="eligibility.product_holding"
                                    render={arrayHelpers => (
                                    <div>
                                        {values.eligibility && values.eligibility.product_holding &&
                                        values.eligibility.product_holding.length > 0 ? (
                                        values.eligibility.product_holding.map((item, index) => (
                                            <div key={index}>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Product Type:</label>
                                                    <Select
                                                        className="col-md-8"
                                                        name={`eligibility.product_holding.${index}.product_type`}
                                                        options={productTypes}
                                                        defaultValue={
                                                            { 
                                                                value: values.eligibility.product_holding[index].product_type, 
                                                                label: (function() {
                                                                    for (let product_type of productTypes) {
                                                                        if (product_type.value === values.eligibility.product_holding[index].product_type) {
                                                                            return product_type.label
                                                                        } 
                                                                    }
                                                                    return values.eligibility.product_holding[index].product_type
                                                                }())
                                                            }
                                                        }
                                                        onChange={(selected) => setFieldValue(`eligibility.product_holding.${index}.product_type`, selected.value)} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Required:</label>
                                                    <Switch
                                                        className="col-md-8"
                                                        checked={values.eligibility.product_holding[index].required}
                                                        onChange={() => setFieldValue(`eligibility.product_holding.${index}.required`, !values.eligibility.product_holding[index].required)} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Minimum Tenure Months:</label>
                                                    <Field
                                                        className="col-md-8"
                                                        type="text" name={`eligibility.product_holding.${index}.min_tenure_months`} />
                                                </div>
                                                <div className="row mt-40 px-5">
                                                    <label className="col-md-2">Description:</label>
                                                    <FieldArray
                                                            className="col-md-8"
                                                            name={`eligibility.product_holding.${index}.description`}
                                                            render={arrayHelpers => (
                                                            <div>
                                                                {values.eligibility && values.eligibility.product_holding &&
                                                                values.eligibility.product_holding[index].description && 
                                                                values.eligibility.product_holding[index].description.length > 0 ? (
                                                                values.eligibility.product_holding[index].description.map((description, sub_index) => (
                                                                    <div key={index + 'eligibility.product_holding' + sub_index}>
                                                                        <Field className="col-md-6" name={`eligibility.product_holding.${index}.description.${sub_index}`} />
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.remove(sub_index)}
                                                                        >
                                                                            -
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.insert(sub_index, '')}
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                ))
                                                                ) : (
                                                                <button type="button" onClick={() => arrayHelpers.push('')}>
                                                                    Add Description
                                                                </button>
                                                                )}
                                                            </div>
                                                            )}
                                                        />
                                                </div>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.push(_.cloneDeep(loan_offer_product_holding))}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        ))
                                        ) : (
                                        <button type="button" onClick={() => arrayHelpers.push(_.cloneDeep(loan_offer_product_holding))}>
                                            Add Product Holding
                                        </button>
                                        )}
                                    </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            --- TO BE DEPRECATED FIELDS ARE LISTED BELOW ---
                        </h4>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Qualifications
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Qualifications:</label>
                            <StepsTextForm namePrefix={`qualifications`} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Submit Requirements
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Submit Requirements:</label>
                            <StepsTextForm namePrefix={`submit_requirements`} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Documents Proof Of Identity
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">
                                Documents Proof Of Identity:
                            </label>
                            <StepsTextForm namePrefix={`documents_proof_of_identity`} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Document Proof Of Income
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">
                                Document Proof Of Income:
                            </label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Cardholder:</label>
                                <StepsTextForm namePrefix={`document_proof_of_income.cardholder`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">OFW:</label>
                                <StepsTextForm namePrefix={`document_proof_of_income.ofw`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Salaried:</label>
                                <StepsTextForm namePrefix={`document_proof_of_income.salaried`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Self Employed:</label>
                                <StepsTextForm namePrefix={`document_proof_of_income.self_employed`} />
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Minimum Income
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Minimum Income:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Professional:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="minimum_income.professional" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="minimum_income.professional" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Salaried:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="minimum_income.salaried" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="minimum_income.salaried" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Self Employed:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="minimum_income.self_employed" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="minimum_income.self_employed" component="div" />
                            </div>
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Other Details
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Min Amount:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="min_amount" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="min_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Max Amount:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="max_amount" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="max_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Penalty Fee:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="penalty_fee" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="penalty_fee" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Processing Fee Amount:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="processing_fee_amount" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="processing_fee_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Processing Fee Description:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="processing_fee_description" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Partner:</label>
                            <Select
                                className="col-md-8"
                                name="partner"
                                options={partnerIds}
                                defaultValue={{
                                    value: values.partner,
                                    label: values.partner
                                }}
                                onChange={(selected) => setFieldValue("partner", selected.value)} />
                        </div>
                        {/* <div className="index-container">
                            <button 
                                className="index-button"
                                type="submit" disabled={isSubmitting}>
                                    Save
                            </button>
                        </div> */}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default LoanOfferAction;
import _ from "lodash";


export const earn_slice = {
    minimum: "",
    maximum: "",
    title: "",
    description: ""
}


export const special_earn_criteria = {
    type: "",
    description: "",
    short_description: "",
    tooltip: "",
    currency: "",
    rate: "",
    spend_block: "",
    capping: {
        amount: "",
        period: "",
        currency: "",
        description: ""
    },
    minimum_spend: {
        amount: "",
        description: ""
    },
    maximum_spend: {
        amount: "",
        description: ""
    },
    mccs: [""],
    merchant_names: [""],
    categories: [""],
    modes: [""],
    exclusion_mccs: [""]
}


export const suggested_place = {
    name: "",
    description: "",
    image_url: ""
}


export const data = {
    provider_id: "",
    name: "",
    network: "",
    page_url: "",
    ownership_type: "",
    brand_partner: "",
    primary_type: "",
    tnc_url: "",
    image_url: "",
    currency: "SGD",
    country_code: "SG",
    base_earn: {
        type: "",
        description: "",
        short_description: "",
        tooltip: "",
        currency: "",
        rate: "",
        spend_block: "",
        spend_currency: "SGD",
        capping: {
            amount: "",
            period: "",
            currency: "",
            description: ""
        },
        minimum_spend: {
            amount: "",
            description: ""
        }
    },
    special_earn: {
        tnc_url: "",
        capping: {
            amount: "",
            period: "",
            currency: "",
            description: ""
        },
        minimum_spend: {
            amount: "",
            description: ""
        },
        criterias: [_.cloneDeep(special_earn_criteria)],
        earn_slices: [_.cloneDeep(earn_slice)],
        earn_slices_tooltips: [""]
    },
    key_benefit: {
        tnc_url: "",
        description: [""]
    },
    promotion: {
        tnc_url: "",
        description: [""]
    },
    suggested_places: [_.cloneDeep(suggested_place)],
    redemption: {
        tnc_url: "",
        options: [""]
    },
    reward: {
        tnc_url: "",
        type: "",
        strategies: [""]
    },
    secondary_types: [""],
    lounge: {
        description: "",
        access: false,
        max_visits: ""
    },
    exclusion: {
        mccs: [""],
        categories: [""],
        expressions: [""],
        card_statements: [""],
        merchant_names: [""],
    },
    discontinued: false,
    invite_only: false,
    installment_benefit: false,
    annual_fee: {
        amount: "",
        description: ""
    },
    supplementary_card_fee: {
        amount: "",
        description: ""
    },
    purchase_rate: {
        percent: "",
        description: ""
    },
    cash_rate: {
        percent: "",
        description: ""
    },
    cash_fee_rate: {
        percent: "",
        description: ""
    },
    free_period: "",
    forex_fcy_fee: "",
    forex_dcc_fee: "",
    overlimit_fee: "",
    repayment_rate: {
        percent: "",
        description: ""
    },
    late_payment_fee: "",
    eligibility: {
        citizens: {
            age: "",
            minimum_income: {
                amount: "",
                description: ""
            }
        },
        foreigners: {
            age: "",
            minimum_income: {
                amount: "",
                description: ""
            }
        }
    },
    star_features: [""],
    limit_features: [""],
    joining_benefit: {
        partner: "",
        fulfilments: [""],
        application_url: "",
        product: {
            description: [""],
            criterias: [""],
            features: [""]
        },
        promotion: {
            name: "",
            description: [""],
            expiry_datetime: "",
            criterias: [""],
            tnc_url: ""
        },
        dobin_promotion: {
            fulfilments: [""],
            description: [""],
            expiry_datetime: "",
            criterias: [""],
            tnc_url: ""
        },
        bank_promotions: [""]
    },
    category_ids: [""],
    card_benefits: [""],
    regex: [""],
    featured_position: "",
    app_featured: false,
    display_order: "",
}